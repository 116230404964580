@use 'sass:color'

$white: #ffffff
$black: #000000
$primary: #271a23
$secondary: #c92840

$primary-100: color.adjust($primary, $lightness: 12)
$primary-200: color.adjust($primary, $lightness: 9)
$primary-300: color.adjust($primary, $lightness: 7)
$primary-500: $primary
$primary-700: color.adjust($primary, $lightness: -4)
$primary-900: color.adjust($primary, $lightness: -10)

$secondary-100: color.adjust($secondary, $lightness: 11)
$secondary-200: color.adjust($secondary, $lightness: 8)
$secondary-300: color.adjust($secondary, $lightness: 5)
$secondary-500: $secondary
$secondary-700: color.adjust($secondary, $lightness: -4)
$secondary-900: color.adjust($secondary, $lightness: -10)

:root
  --colors-white: #{$white}
  --colors-white-a80: #{rgba($white, 0.8)}
  --colors-black: #{$black}

  --colors-primary-100: #{$primary-100}
  --colors-primary-200: #{$primary-200}
  --colors-primary-300: #{$primary-300}
  --colors-primary-500: #{$primary-500}
  --colors-primary-700: #{$primary-700}
  --colors-primary-700-a80: #{rgba($primary-700, 0.8)}
  --colors-primary-900: #{$primary-900}

  --colors-secondary-300: #{$secondary-300}
  --colors-secondary-500: #{$secondary-500}
  --colors-secondary-700: #{$secondary-700}
  --colors-secondary-900: #{$secondary-900}
